import axios from 'axios';
import { BASE_URL } from './ip';
import { useDispatch } from 'react-redux';
import { BLOGS_LIST_FAIL, BLOGS_LIST_SUCCESS, TOPICS_LIST_FAIL, TOPICS_LIST_SUCCESS } from '../constants/blogConstants';



export const getAllBlogs = (pageNumber, pageSize) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.get(`${BASE_URL}/blog/list?filter=all&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=publishedDate&order=DESC`, config);

		dispatch({
			type: BLOGS_LIST_SUCCESS,
			payload: data
		});

	} catch (err) {
		dispatch({
			type: BLOGS_LIST_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const editStatusByTopicId = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		console.log(data, 'data')
		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/topic`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const editBlogById = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		console.log(data, 'data')
		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/create`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const statusChangeBlogById = async (data, id) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		data = { ...data, client: "360Insurance" }
		return axios.patch(`${BASE_URL}/blog/change-status/${id}`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const createBlog = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/create`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};


export const createTopic = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/topic`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const createMainTopic = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/main_topic`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const searchBlogList = (query) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/all/search?search=${query}`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const updateTopicById = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/topic`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const saveBlogSetting = async (data) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		data = { ...data, client: "360Insurance" }
		return axios.post(`${BASE_URL}/blog/setting`, data, config);

	} catch (err) {
		return Promise.reject(err);
	}
};
export const getBlogSetting = async (page) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/setting?pageName=${page}&&client=360Insurance`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const getBlogById = (id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/byId/${id}`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteTopicById = async (id) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.delete(`${BASE_URL}/blog/topic/${id}`, config);

	} catch (err) {
		return Promise.reject(err);
	}
};

export const getAllTopics = (filter = 'all') => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		const { data } = await axios.get(`${BASE_URL}/blog/topic?filter=${filter}`, config);

		dispatch({
			type: TOPICS_LIST_SUCCESS,
			payload: data
		});

	} catch (err) {
		dispatch({
			type: TOPICS_LIST_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const getAllTopicsAPI = async (filter = 'all') => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/topic?filter=${filter}`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};
export const getAllMainTopicsAPI = async (filter = 'all') => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/main_topic?filter=${filter}`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};
export const getPublishedBlogAPI = async (filter = 'all', pageNumber = 1, pageSize = 6) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/public?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=publishedDate&order=DESC`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};
export const getPublishedBlogByTopic = async (topic = 'all', pageNumber = 1, pageSize = 6) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/byTopic/${topic}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=publishedDate&order=DESC`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};
export const searchPublishedBlogs = async (search = '', pageNumber = 1, pageSize = 6) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/published/search?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};
export const getPublishedBlogById = async (id) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return axios.get(`${BASE_URL}/blog/published/${id}`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};